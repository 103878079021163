import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { MyApp } from './app.component';

import { CustomCameraComponentModule } from '../components/custom-camera/custom-camera.module';
import { CustomLoadingComponentModule } from '../components/custom-loading/custom-loading.module';

import { CameraProvider } from '../providers/camera/camera';
import { LoadingProvider } from '../providers/loading/loading';
import { ReservationsGetScheduleProvider } from '../providers/reservations-get-schedule/reservations-get-schedule';
import { AppMonitorV2ServiceProvider } from '../providers/servicer/app-monitor-v2-service';
import { StationV2ServiceProvider } from '../providers/servicer/station-v2-service';
import { UserV2ServiceProvider } from '../providers/servicer/user-v2-service';
import { UserVehicleV2ServiceProvider } from '../providers/servicer/user-vehicle-v2-service';
import { PlaylistServiceProvider } from '../providers/playlist/playlist-provider';
import { PlaylistScheduleProvider } from '../providers/playlist-schedule/playlist-schedule';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CacheUpdateService } from '../providers/servicer/cache-update-service';
import { StaticTableLoader } from './services/StaticTableLoader';
import { CsvLoader } from './services/csv-loader';

@NgModule({
  declarations: [
    MyApp
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    HttpClientModule,
    CustomCameraComponentModule,
    CustomLoadingComponentModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorkerRegister
      }
    )
  ],
  bootstrap: [MyApp],
  entryComponents: [
    MyApp
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AndroidPermissions,
    {provide: ErrorHandler, useClass: ErrorHandler},
    CameraProvider,
    LoadingProvider,
    ReservationsGetScheduleProvider,
    AppMonitorV2ServiceProvider,
    StationV2ServiceProvider,
    UserV2ServiceProvider,
    UserVehicleV2ServiceProvider,
    PlaylistServiceProvider,
    PlaylistScheduleProvider,
    CacheUpdateService,
    StaticTableLoader,
    CsvLoader
  ]
})
export class AppModule {}
