import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StationForSearch } from './servicer/models/stationForSearch';
import { UserV2ServiceProvider } from '../../providers/servicer/user-v2-service';
import { StationV2ServiceProvider } from '../../providers/servicer/station-v2-service';

import { ApiResultType } from '../../providers/servicer/types/api-result-type';
import { MapperUtil } from '../../app/utilities/mapper-util';
import { Observable, of, Subscriber, throwError } from 'rxjs';
import { Station } from './servicer/models/station';
import { catchError, delay, filter, mergeMap, retryWhen } from 'rxjs/operators';
import { UserGuestLoginV2Response } from '../../providers/servicer/models/user-guest-login-v2-response';
import { RetryGuestLoginFlagService } from './retry-guest-login-flag.service';


@Injectable()
export class StaticTableLoader {

  public serverCacheUpdateMode = 0;
  private tmpData: any;

  readonly CODE_ROW_COLUMN = 0;
  readonly TYPE_ROW_COLUMN = 1;
  readonly NAME_ROW_COLUMN = 2;
  readonly YOMI_ROW_COLUMN = 3;
  readonly DISTRICT_L_COLUMN = 4;
  readonly DISTRICT_S_COLUMN = 5;
  readonly LAT_ROW_COLUMN = 6;
  readonly LON_ROW_COLUMN = 7;
  readonly ADDRESS_ROW_COLUMN = 8;
  readonly NICKNAME_COLUMN = 10;
  readonly NICKNAME_KANA_COLUMN = 11;
  readonly KEYWORD_COLUMN = 12;
  readonly KEYWORD_KANA_COLUMN = 13;

  static readonly TYPE_MAIN = 1;
  static readonly TYPE_CENTRAL_KEYWORD = 2;
  static readonly TYPE_CENTRAL_NOT_KEYWORD = 3;
  static readonly TYPE_PERIPHERAL = 4;


  readonly defaultStationTables = {
    DoStationCodes: null,
    stationSession: null
  };
  readonly defaultConstTables = {
    userStations: null,
    timeSchedule: null,
    datacsv: null,
    puStations: null,
    nextLoadDate: 0
  };

  private envStr: string;

  constructor(
    public userV2Service: UserV2ServiceProvider,
    public stationV2Service: StationV2ServiceProvider,
    private retryGuestLoginFlagService: RetryGuestLoginFlagService
  ) {
    this.setEnv(environment.setting.servicerApiUrl);
    this.serverCacheUpdateMode = environment.setting.serverCacheUpdateMode;
  }

  public deleteCache() {
    this.resetGlobals();
    localStorage.removeItem(this.getStorageKey('consttable'));
    localStorage.removeItem(this.getStorageKey('stationtable'));
  }

  public setEnv(servicewrUrl: string) {
    this.envStr = (servicewrUrl.indexOf('localhost') < 0) ? '_prod' : '_local';
  }

  public resetGlobals() {
    environment.consttables = this.defaultConstTables;
    environment.stationtables = this.defaultStationTables;
  }

  /**
   * ストレージキーの取得
   * @param prefix ストレージキーの固定部名
   * @returns ストレージキー
   */
  private getStorageKey(prefix: string): string {
    return prefix + this.envStr;
  }

  /** スマモビ運行時間を整形 */
  private parseTimeSchedule(text: string): string[][] {
    let lines = text.split(/\r\n|\r|\n/);
    let tmp: string[], tmp2: string[];
    let list = [];
    if (lines.length > 1) {
      for (let i = 1; i < lines.length; i++) {
        if (lines[i] !== '') {
          tmp = lines[i].split('：');
          tmp2 = tmp[1].split(' - ');
          list[i - 1] = [tmp[0], tmp2[0], tmp2[1]];
        }
      }
    }
    return list;
  }

  /**
   * 
   * @param response S3から取得したdata.csv情報
   * @returns 
   */
  private parseCsvData(csvData: string): afterFilterCsvData {
    const tmp = csvData.split(/\r\n|\r|\n/);
    let parseData: afterFilterCsvData = {
      'stations': new Array<StationForSearch>(),
      'stationsForSearch': new Array<StationForSearch>(),
      'stationsForDoSearch': new Array<StationForSearch>(),
      'nicknameList': new Array<Nickname>(),
      'keywordList': new Array<Keyword>()
    };

    for (let index = 1; index < tmp.length; index++) {
      //カンマ区切りで配列に分割
      let row = tmp[index].split(',');
      const data: StationForSearch = {
        id: -1,
        code: row[this.CODE_ROW_COLUMN],
        type: Number.parseInt(row[this.TYPE_ROW_COLUMN]),
        name: row[this.NAME_ROW_COLUMN],
        yomi: row[this.YOMI_ROW_COLUMN],
        address: row[this.ADDRESS_ROW_COLUMN],
        lat: Number.parseFloat(row[this.LAT_ROW_COLUMN]),
        lon: Number.parseFloat(row[this.LON_ROW_COLUMN]),
        districtL: row[this.DISTRICT_L_COLUMN],
        districtS: row[this.DISTRICT_S_COLUMN],
        iconUrl: '',
        imgUrl: '',
        imgDropOffUrl: '',
        nickname: this.parseNickname(row[this.NICKNAME_COLUMN]),
        nicknameKana: this.parseNickname(row[this.NICKNAME_KANA_COLUMN]),
        keyword: this.parseKeyword(row[this.KEYWORD_COLUMN]),
        keywordKana: this.parseKeyword(row[this.KEYWORD_KANA_COLUMN])
      }
      parseData['stations'].push(data);

      for (let i = 0; i < data.nickname.length; i++) {
        let nickname: Nickname = {
          name: data.nickname[i],
          yomi: data.nicknameKana[i],
          station: data
        };

        parseData['nicknameList'].push(nickname);

        if (data.keyword == null) continue;

        for (let j = 0; j < data.keyword[i].length; j++) {
          let element = parseData['keywordList'].find((keyword) => keyword.word === data.keyword[i][j]);
          if (element) {
            element.nickName.push(nickname);
          }
          else {
            let keyword: Keyword = {
              word: data.keyword[i][j],
              yomi: data.keywordKana[i][j],
              nickName: new Array<Nickname>(nickname)
            };

            parseData['keywordList'].push(keyword);
          }
        }
      }
      //typeが0の場合検索候補から外す
      if (data.type === 0) continue;

      parseData['stationsForSearch'].push(data);
      parseData['stationsForDoSearch'].push(data);
    }
    return parseData;

  }

  private parseNickname(nickNames: string): string[] {
    return nickNames.split('/');
  }

  private parseKeyword(keyword: string): string[][] {
    let keywords: string[][];
    if (keyword === '') return keywords;
    const regex = /\{([^\[\}\s]+)/g;
    const point = keyword.match(regex)
      .map((s) => s.substring(1, s.length));
    if (point == null) return keywords;

    keywords = new Array(point.length);
    for (let i = 0; i < point.length; i++) {
      keywords[i] = point[i].split('/');
    }
    return keywords;
  }


  /**
   * 駅関連情報を初期化
   * @returns 初期化の成功失敗
   */
  public initStationList(): Observable<boolean> {
    return new Observable<boolean>((observe: Subscriber<boolean>) => {
      //environment上に、data.csvがない場合
      if (!environment.consttables.datacsv) {
        const key = this.getStorageKey('constTable');
        const tableData = localStorage.getItem(key);
        if (tableData) {
          environment.consttables = JSON.parse(tableData);
        }
      }
      //environment上に、降車地候補地がない場合
      if (!environment.stationtables.DoStationCodes) {
        const key = this.getStorageKey('stationTable');
        const tableData = localStorage.getItem(key);
        if (tableData) {
          environment.stationtables = JSON.parse(tableData);
        }
      }

      this.updateStationList().subscribe((res: boolean) => {
        observe.next(res)
      });
    })
  }

  /**
   * 降車地一覧取得
   * @returns 降車地情報取得　成功（true) 失敗(false)
   */
  public reloadStationTable(): Observable<boolean> {
    return new Observable<boolean>((observe: Subscriber<boolean>) => {
      if (environment.stationtables.stationSession) {
        const userStation = environment.consttables.userStations.find((station: Station) => station.code === environment.stationtables.stationSession.code);
        this.getStationTable(userStation).subscribe((saveResult: boolean) => {
          observe.next(saveResult);
        });
      }
    })
  }

  /**
   * 降車地一覧を取得
   *
   * @param userStation 
   * @param stationTables 
   * @returns 降車地一覧情報の取得成功失敗
   */
  public getStationTable(userStation: Station): Observable<boolean> {
    return new Observable<boolean>((observe: Subscriber<boolean>) => {
      this.userV2Service.doStationCode(environment.APIaccessUserId, userStation['code']).subscribe((response) => {
        if (response.result != ApiResultType.SUCCESS) {
          observe.next(false);
          return;
        }

        //駅情報と降車地候補を初期化
        const localStationTables = {
          DoStationCodes: null,
          stationSession: null,
        }

        //降車地一覧を取得
        localStationTables.DoStationCodes = MapperUtil.mapperUserPuStationCodesV2ApiResponseToDoStationCodes(
          response, environment.consttables.datacsv['stationsForDoSearch']);
        
        localStationTables.stationSession = {
          id: userStation['id'],
          language: environment.setting.language,
          code: userStation['code'],
          name: userStation['name'],
          lat: userStation['lat'],
          lon: userStation['lon'],
          iconUrl: userStation['iconUrl'],
          imgUrl: userStation['imgUrl']
        }

        //取得した降車地情報を格納
        environment.stationtables = localStationTables;

        //ストレージの初期化と更新
        const storageKey = this.getStorageKey('stationTable');
        localStorage.removeItem(storageKey);
        localStorage.setItem(storageKey, JSON.stringify(localStationTables));
        observe.next(true);
        return;
      });
    })
  }

  /**
   *  駅一覧情報の取得とCSV情報の更新
   * @returns 処理の成功、失敗を返す
   */
  private updateStationList(): Observable<boolean> {
    //リトライ回数
    const MAX_RETRY_INDEX = 3;
    //リトライ間隔（30S）
    const DELAY_TIME = 30 * 1000;

    return new Observable<boolean>((observe) => {
      this.userV2Service.guestLogin('').pipe(
        retryWhen(errors =>
          errors.pipe(
            filter(() => {
              return this.retryGuestLoginFlagService.getFlag()
            }),
            mergeMap((error, index) => {
              if (index >= MAX_RETRY_INDEX) {
                // 最大リトライ回数に到達したらエラーを投げる
                return throwError(error);
              } else {
                // リトライ間隔を取る
                return of(null).pipe(delay(DELAY_TIME));
              }
            })
          )
        )
        , catchError((error) => {
          return of({} as UserGuestLoginV2Response);
        })
      ).subscribe((response: UserGuestLoginV2Response) => {
        if (response.result != ApiResultType.SUCCESS) {
          observe.next(false);
          return;
        }
        this.userV2Service.stations(response.user_id).subscribe((userV2Response) => {
          if (userV2Response.result != ApiResultType.SUCCESS) {
            observe.next(false);
            return;
          }

          this.stationV2Service.stations({language: environment.setting.language}).subscribe((stationV2Response) => {
            if(stationV2Response.result!==ApiResultType.SUCCESS){
              observe.next(false);
              return;
            }
            //初期化
            this.tmpData = this.defaultConstTables;
            this.tmpData.puStations = stationV2Response.stations;
          
            this.tmpData.userStations = userV2Response.stations;
            this.initCsvData().subscribe(() => {
              observe.next(true);
            });
          });
        });
      });
    })
  }

  /**
   * csvDataを更新
   */
  private initCsvData(): Observable<void> {
    return new Observable((observe) => {
      this.constLoad().then(([csv, timeSchedule]) => {
        //初期化
        environment.consttables = this.defaultConstTables;
        
        const nowDate = new Date();

        // 次回更新期日を翌日0時に設定
        const tmpDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1, 0, 0, 0);

        if (environment.setting.serverCacheUpdateMode !== this.serverCacheUpdateMode) {
          this.serverCacheUpdateMode = environment.setting.serverCacheUpdateMode;
        }
        this.tmpData.nextLoadDate = tmpDate.getTime();
        this.tmpData.timeSchedule = this.parseTimeSchedule(timeSchedule);
        this.tmpData.datacsv = this.parseCsvData(csv);
        environment.consttables = this.tmpData;

        const storageKey = this.getStorageKey('constTable');
        localStorage.removeItem(storageKey);
        localStorage.setItem(storageKey, JSON.stringify(this.tmpData));
        observe.next();
      });
    });
  }

  /**
   * data.csvとスケジュール情報の取得
   * @returns 
   */
  private async constLoad(): Promise<[string, string]> {
    return await Promise.all([
      this.loadCsv(),
      this.loadTimeSchedule()
    ]);
  }

  /**
   * data.csvの情報を取得
   * @returns data.csvの情報
   */
  private async loadCsv(): Promise<string> {
    const url = (environment.setting.servicerApiUrl.indexOf('localhost') < 0) ?
      environment.setting.servicerApiUrl + environment.stationCsvUrl : 'assets/data.csv';
    return fetch(url)
      .then(response => {
        return response.text();
      });
  }

  /**
   * スマモビ運行時間を取得
   * @returns スマモビ運行時間
   */
  private async loadTimeSchedule(): Promise<string> {
    const url = (environment.setting.servicerApiUrl.indexOf('localhost') < 0) ? environment.setting.servicerApiUrl + environment.timeScheduleUrl : 'assets/opening_hours.txt';
    return fetch(url)
      .then(response => {
        return response.text();
      });
  }
}

export interface Nickname {
  name: string;
  yomi: string;
  station: StationForSearch;
}

export interface Keyword {
  word: string;
  yomi: string;
  nickName: Nickname[];
}

interface afterFilterCsvData {
  stations: StationForSearch[];
  stationsForSearch: StationForSearch[];
  stationsForDoSearch: StationForSearch[];
  nicknameList: Nickname[];
  keywordList: Keyword[];
}