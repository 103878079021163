import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { ReplaySubject, Observable, Subscription, timer, defer } from "rxjs";
import { concatMap, filter } from "rxjs/operators";

import { PlaylistServiceProvider } from "../playlist/playlist-provider";

import { Const } from "../../providers/const";
import { environment } from "../../environments/environment";
import { PlaylistResponse } from "../playlist/models/playlist-response";
import moment from "moment";

/**
 * プレイリストを定期的に取得する.
 */
@Injectable()
export class PlaylistScheduleProvider {
  /* プレイリストの取得結果 next のみ */
  public playlist$: ReplaySubject<PlaylistResponse> =
    new ReplaySubject<PlaylistResponse>(1);

  private schedule$: Subscription = null;
  private isValid = false;

  private playlistInfo = {
    updatedAt: 0,
  };
  private isPlaylistInfoLoaded = false;
  /** 起動後1度だけ判定したい */
  private isAppLaunchDidLoad = false;

  constructor(
    public toast: ToastController,
    public playlistService: PlaylistServiceProvider
  ) {}

  /**
   * LocalStorageから設定値を読み込みます（1度のみ）
   * `playlistInfoStorageKey`は他クラスでの書き込みを想定していません
   */
  loadPlaylistInfoIfNeeded() {
    if (this.isPlaylistInfoLoaded) {
      return;
    }

    const playlistInfoStorage = localStorage.getItem(
      Const.playlistInfoStorageKey
    );
    if (playlistInfoStorage) {
      this.playlistInfo = JSON.parse(playlistInfoStorage);
    }
    environment.playlistInfo = this.playlistInfo;
    this.isPlaylistInfoLoaded = true;
  }

  /**
   * LocalStorageへ設定値を取得します
   * `playlistInfoStorageKey`は他クラスでの書き込みを想定していません
   */
  getPlaylistInfo() {
    this.loadPlaylistInfoIfNeeded();
    const info = { ...this.playlistInfo };
    console.log("** get playlist", info);
    return info;
  }

  /**
   * LocalStorageへ設定値を保存します
   * `playlistInfoStorageKey`は他クラスでの書き込みを想定していません
   */
    private savePlaylistInfo() {
      this.loadPlaylistInfoIfNeeded();
  
      environment.playlistInfo = this.playlistInfo;
      localStorage.setItem(
        Const.playlistInfoStorageKey,
        JSON.stringify(this.playlistInfo)
      );
    }

  /**
   * 確認が成功した日時を保存します
   */
  private saveUpdatedAt() {
    const now = Date.now();
    this.playlistInfo.updatedAt = now;
    this.savePlaylistInfo();
  }

  /**
   * キャッシュ画像取得
   *
   * @param url string
   * @returns Promise<string>
   */
  async getCacheImageUrl(url: string): Promise<string> {
    return await this.playlistService.getCacheImageUrl(url);
  }

  /**
   * 定期取得を開始する
   * 直後にローカルに保存されているプレイリストを取得します（通信なし）
   * アプリ起動後に1度更新確認を行い当日の確認が行われていない場合にデータを取得します
   * その後スケジュールに従い定期取得を行います
   */
  start() {
    this.isValid = true;

    this.loadPlaylistInfoIfNeeded();
    this.getLocalPlaylist();

    if (this.isAppLaunchDidLoad) {
      this.process(this.nextScheduleUpdateTime(), this.retryMaxCount());
    } else {
      this.isAppLaunchDidLoad = true;

      // 当日取得していなければ即時、していればスケジュールに従う
      const processDate = this.todayUpdated()
        ? this.nextScheduleUpdateTime()
        : new Date();
      this.process(processDate, this.retryMaxCount());
    }
  }

  /**
   * ローカルに保存されているプレイリストを取得します（通信なし）
   */
  private async getLocalPlaylist() {
    const request = this.requestParam();
    try {
      const result = await this.playlistService.getCachePlaylist(request);
      this.playlist$.next(result);
    } catch (e) {
      const result = <PlaylistResponse>{
        result: 0,
        playlistID: request.playlistID,
      };
      this.playlist$.next(result);
    }
  }

  /**
   * 設定画面プレイリスト再読み込み欄の更新ボタン押下時に、広告情報をCloudFrontから再取得する
   */
  public async update() {
    const request = this.requestParam();
    const updatePlaylist$ = (): Observable<PlaylistResponse> =>
      defer(async () => await this.playlistService.updatePlaylist(request));
    updatePlaylist$().subscribe({
      error: async (error: PlaylistResponse) => {
        (
          await this.toast.create({
            message: "プレイリスト再読み込み失敗",
            position: "top",
            duration: 2000,
          })
        ).present();
      },
      complete: async () => {
        (
          await this.toast.create({
            message: "プレイリスト再読み込み成功",
            position: "top",
            duration: 2000,
          })
        ).present();
        this.saveUpdatedAt();
      },
    });
  }

  /**
   * スケジュール実行処理
   * 初期設定の値でスケジュールを実行する
   * 処理結果を playlist$ へ通知します
   * 成功の場合に処理日時を保存します
   * エラーの場合初期設定に従いリトライを行う
   * @param date 処理を行う日時
   * @param retry リトライ回数
   */
  private process(date: Date, retry: number) {
    const request = this.requestParam();
    const updatePlaylist$ = (): Observable<PlaylistResponse> =>
      defer(async () => await this.playlistService.updatePlaylist(request));

    if (this.schedule$) {
      this.schedule$.unsubscribe();
      this.schedule$ = null;
    }

    this.schedule$ = timer(date)
      .pipe(
        filter(() => this.isValid),
        filter(() => retry >= 0),
        concatMap(() => updatePlaylist$())
      )
      .subscribe({
        next: (v) => {
          this.saveUpdatedAt();
          this.playlist$.next(v);
        },
        error: (e) => {
          if (retry <= 0) {
            this.process(this.nextScheduleUpdateTime(), this.retryMaxCount());

            this.playlistService.deletePlaylist();
            const result = <PlaylistResponse>{
              result: 0,
              playlistID: request.playlistID,
            };
            this.playlist$.next(result);
          } else {
            const interval = this.retryInterval() * 1; // *1必要
            this.process(new Date(new Date().getTime() + interval), retry - 1);
          }
        },
        complete: () => {
          this.process(this.nextScheduleUpdateTime(), this.retryMaxCount());
        },
      });
  }

  /**
   * 定期取得を停止する
   */
  stop() {
    this.isValid = false;

    if (this.schedule$) {
      this.schedule$.unsubscribe();
      this.schedule$ = null;
    }
  }

  /**
   * 初期設定の機体番号から取得するプレイリストパラメータを取得
   * @returns {number} プレイリストのリクエストパラメータ
   */
  private requestParam() {
    const id = environment.devices[environment.setting.deviceNumberIndex].id;
    return { playlistID: id };
  }

  /**
   * 初期設定のリトライ最大回数
   * @returns {number} リトライの最大回数
   */
  private retryMaxCount(): number {
    return environment.setting.playlistFetchRetryCount;
  }

  /**
   * 初期設定のリトライ間隔
   * @returns {number} リトライの更新間隔
   */
  private retryInterval(): number {
    const interval = environment.setting.playlistFetchRetryInterval;
    return interval < 0 ? 0 : interval;
  }

  /**
   * 初期設定から次の更新日時を取得する
   * 設定時刻が現在時刻を超えている場合は翌日になります
   * @return {Date} 次の更新日時
   */
  private nextScheduleUpdateTime(): Date {
    const nowDate = moment();   
    const setTimeDate = moment(environment.setting.playlistUpdateTime, 'hh:mm');
    
    if (setTimeDate <= nowDate) {
      const PLUS_ONE_DAY = 1;
      setTimeDate.add(PLUS_ONE_DAY, 'day');
    }

    return setTimeDate.toDate();
  }

  /**
   * 本日のデータ取得を行い成功しているか
   * @return {boolean} true: 本日取得済み
   */
  private todayUpdated(): boolean {
    this.loadPlaylistInfoIfNeeded();

    const now = new Date(Date.now());
    const updatedAt = new Date(this.playlistInfo.updatedAt);
    const isTodayUpdated =
      now.getFullYear() == updatedAt.getFullYear() &&
      now.getMonth() == updatedAt.getMonth() &&
      now.getDay() == updatedAt.getDay()
        ? true
        : false;

    return isTodayUpdated;
  }
}
